import React from "react";

import Header from "./components/Header/Header";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home-Page/Home.jsx";
import Guidelines from "./Pages/Guidelines-Page/Guidelines";
import Footer from "./components/Footer/Footer";
import PrivacyPolicy from "./Pages/Guidelines-Page/PrivacyPolicy";
import Refunds from "./Pages/Guidelines-Page/Refunds";
import Cancellation from "./Pages/Guidelines-Page/Cancellation";
import Subscription from "./Pages/Guidelines-Page/Subscription";
import About from "./Pages/About-Page/About";
import ComingSoon from "./components/Coming Soon/ComingSoon";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/guidelines" element={<Guidelines />} />
        <Route path="/guidelines/privacy" element={<PrivacyPolicy />} />
        <Route path="/guidelines/refunds" element={<Refunds />} />
        <Route path="/guidelines/cancellation" element={<Cancellation />} />
        <Route path="/guidelines/subscription" element={<Subscription />} />
        {/* <Route path="/contact-us" element={<Contact />} /> */}
        <Route path="/coming-soon" element={<ComingSoon />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
