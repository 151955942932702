import React from 'react'
import { FaHandsHelping, FaLock, FaComments, FaFingerprint } from 'react-icons/fa';
import './Guidelines.css'
const Guidelines = () => {
  return (
    <>
     <div className="guidelines-container">
      <h2 style={{ fontSize:"20px",
 }}>Community guidelines</h2>
      <p>
        This Platform is a place for sharing knowledge, asking questions, and supporting each other in
        the field of biomedical engineering. Please keep these guidelines in mind to ensure a positive
        experience for everyone:
      </p>
      <div className="guidelines">
        <div className="guideline">
        
        <h3 style={{fontSize:"15px"}}  >  <FaHandsHelping size={25} style={{marginRight:"9px"}}/> Be Respectful:</h3>
          <p>Treat everyone with respect. Healthy debates are welcome, but kindness is required.</p>
        </div>
        <div className="guideline">
          
          <h3 style={{fontSize:"15px"}}> <FaLock size={25} style={{marginRight:"9px"}} /> No Spam:</h3>
          <p>Avoid posting promotional content or irrelevant links.</p>
        </div>
        <div className="guideline">
         
          <h3 style={{fontSize:"15px"}}> <FaComments size={25} style={{marginRight:"9px"}} /> Stay on Topic:</h3>
          <p>Keep discussions relevant to biomedical engineering and related fields.</p>
        </div>
        <div className="guideline">
       
          <h3 style={{fontSize:"15px"}}>   <FaFingerprint size={25} style={{marginRight:"9px"}} />Confidentiality:</h3>
          <p>Do not share personal or sensitive information about yourself or others.</p>
        </div>
      </div>
    </div>
    
    
    </>
  )
}

export default Guidelines