import React from "react";
import student from "../../images/card_bme1.jpg";
import card5 from "../../images/professional_bme.jpg";
import card6 from "../../images/researchers in home page.jpg";
import card7 from "../../images/buyers_bme.jpg";
import cardExtra from "../../images/cardExtra.jpg";
import card9 from "../../images/job_bme.jpg";

import "./Card.css";
export const Card = () => {
  return (
    <>
      <h1
        style={{
          marginTop: "20px",
          fontSize: "20px",
          fontWeight: "500",
          marginBottom: "10px",
          textAlign: "center",
        }}
      >
        Who can benefit from the App?
      </h1>
      <div className="container">
        <div className="cards">
          <div className="card">
            <div className="card_img">
              <img src={student} alt="pic" />
            </div>
            <div className="card_text">
              <p>
                {" "}
                <strong>Students:</strong> Our app is an invaluable resource for
                students pursuing degrees in biomedical engineering or related
                fields. It enhances the learning experience by offering hands-on
                experiences and access to valuable resources. Whether you're a
                medical student, engineering student, or a student in a related
                discipline, this app can supplement your education and help you
                stay up-to-date with the latest developments in the field.
              </p>
            </div>
          </div>
          <div className="card">
            <div className="card_img">
              <img src={card5} alt="" />
            </div>
            <div className="card_text">
              <p>
                {" "}
                <strong>Medical Professionals:</strong> For healthcare
                practitioners, this app is a gateway to staying informed about
                the latest technologies and medical advancements. It offers a
                curated feed of information on groundbreaking medical
                innovations, which can transform the way you diagnose and treat
                patients. As a medical professional, you can benefit from the
                app's continuous updates and expert insights to deliver the best
                care to your patients.
              </p>
            </div>
          </div>
          <div className="card">
            <div className="card_img">
              <img src={card6} alt="" />
            </div>
            <div className="card_text">
              <p>
                {" "}
                <strong>Researchers:</strong> Access to a wealth of knowledge
                and data is crucial for researchers in the biomedical
                engineering and related areas. Our app provides a platform for
                researchers to stay updated with the most recent studies,
                breakthroughs, and datasets. It's an invaluable tool to support
                your research efforts, helping you make advancements in your
                chosen field.
              </p>
            </div>
          </div>
          <div className="card">
            <div className="card_img">
              <img src={card9} alt="" />
            </div>
            <div className="card_text">
              <p>
                {" "}
                <strong>Enthusiasts:</strong> If you're someone with a passion
                for learning about groundbreaking medical technologies and
                innovations, this app caters to your curiosity. You don't need a
                formal background in biomedical engineering to benefit from the
                app. It's designed to be user-friendly and informative, making
                it easy for enthusiasts to explore and understand the exciting
                developments in the field.
              </p>
            </div>
          </div>
          <div className="card">
            <div className="card_img">
              <img src={cardExtra} alt="" />
            </div>
            <div className="card_text">
              <p>
                {" "}
                <strong>Job Seekers:</strong> Job seekers looking for
                opportunities in the biomedical engineering and related
                industries can utilize the app to find job listings, stay
                updated on industry trends, and access resources that will help
                them prepare for interviews. It serves as a valuable tool in
                your job search and career development.
              </p>
            </div>
          </div>
          <div className="card">
            <div className="card_img">
              <img src={card7} alt="" />
            </div>
            <div className="card_text">
              <p>
                {" "}
                <strong>Buyers:</strong> Individuals and organizations looking
                to purchase medical equipment, devices, or related products can
                use the app to discover and compare options. It provides a
                marketplace for buyers to browse a wide range of products and
                make informed decisions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
