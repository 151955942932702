import React, { useEffect, useState } from 'react';
import slider_bme1 from '../../images/1.png';
import slider_bme2 from '../../images/2.png';
import slider_bme3 from '../../images/3.png';
import slider_bme4 from '../../images/4.png';
import './Hero.css';
import LazyLoad from 'react-lazyload';

const Hero = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [slider_bme1, slider_bme2, slider_bme3, slider_bme4];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [images.length]);

  return (
    <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
      <LazyLoad height={600} offset={100}>
        {images.map((image, index) => (
          <div key={index} className={`carousel-item ${index === currentIndex ? 'active' : ''}`}>
            <img src={image} className="d-block custom-img-class" alt={`Slide ${index + 1}`} />
          </div>
        ))}
        </LazyLoad>
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev" onClick={() => setCurrentIndex((currentIndex - 1 + images.length) % images.length)}>
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next" onClick={() => setCurrentIndex((currentIndex + 1) % images.length)}>
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Hero;
