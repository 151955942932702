import React, { useRef } from "react";
import "./Home.css";
import Hero from "../Hero-HomePage/Hero";
import { NavLink, Link } from "react-router-dom";
import about from "../../images/about_bme0.jpg";
import logo_title from "../../images/logo_title.png";
import appDownload from "../../images/app_download.jpg";
import videoSource1 from "../../images/Video/introVideo01.mp4";  
import { Card } from "../Card-HomePage/Card";
import Button from "@mui/material/Button";
import LazyLoad from "react-lazyload";

const Home = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const videoRef = useRef(null);

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  return (
    <div className="homepage">
      <Hero />

      <h1 className="about-header">About-Us</h1>
      <div className="container-about">
        <div className="about-home">
          <div className="about-content">
            <p>
              We are a team of professionals, passionate about the innovative
              and dynamic field of biomedical engineering. By providing a
              platform that promotes knowledge sharing, collaboration, and
              innovation, we hope to inspire the next generation of biomedical
              engineers and contribute to a healthier and more advanced world.
              <br />
              <strong style={{ fontWeight: "bolder" }}> Our mission</strong> is
              to provide a comprehensive resource hub for all things related to
              biomedical engineering, from cutting-edge research to practical
              applications that impact lives around the world.
              <br />
              <strong style={{ fontWeight: "bolder" }}>Our vision</strong> a
              world where biomedical engineering breakthroughs revolutionize
              healthcare and contribute to global well-being. Through our app,
              we aim to promote awareness and understanding of this
              transformative{" "}
              <Link to="/about" onClick={() => scrollToTop()}>
                <aside
                  style={{
                    fontSize: "14px",
                    marginTop: "20px",
                    padding: "0",
                    listStyle: "none",
                    textDecoration: "none",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      ml: "10px", // Margin left
                      textTransform: "none", // Prevent text from being capitalized
                      backgroundColor: "transparent", // Transparent background
                      color: "#075cab", // Blue text color
                      border: "1px solid #075cab",
                      fontSize: "14px",
                      fontFamily: "Montserrat", // Blue border for visual distinction
                      "&:hover": {
                        backgroundColor: "#e3f2fd", // Light blue background on hover
                        border: "1px solid #075cab", // Ensure border stays the same on hover
                        color: "#075cab",
                        fontSize: "14px",
                        fontFamily: "Montserrat", // Keep text color blue on hover
                      },
                    }}
                  >
                    Read More
                  </Button>
                </aside>
              </Link>
            </p>
          </div>
          <div className="about-image">
            <img src={about} alt="about" />
          </div>
        </div>
      </div>
      <Card />
      <div className="introduction-section">
        <div className="intro-text">
          <img
            src={logo_title}
            alt="logo"
            width="50%"
          />
          <p>
            If you have any questions, suggestions, or would like to contribute,
            feel free to reach out to us.<br />
            <br /> Let's inspire, innovate, and make a positive impact on the
            world through biomedical engineering!
          </p>
          <div className="app-download">
            <NavLink to="/coming-soon" onClick={() => scrollToTop()}>
              <img src={appDownload} alt="logo" />
            </NavLink>
          </div>
        </div>

        <div className="intro-video">
          <LazyLoad height={600} offset={100} >
          <video
            ref={videoRef}
            src={videoSource1}
            autoPlay
            loop
            muted
            width="100%"
            height="auto"
            onClick={handleVideoClick}
          />
          </LazyLoad>
        </div>
      </div>
    </div>
  );
};

export default Home;