import React from "react";
import vendor from "../../images/bme-vendors.png";
import forum_about from "../../images/forum-bme.png";
import job_about from "../../images/about_img1.jpg";
import article_bme from "../../images/bme-articles.png";
import card_1 from "../../images/BME 8.jpg";
import card_2 from "../../images/banner14.png";
import card_3 from "../../images/banner_2.jpg";
import "./About.css";

const About = () => {
  return (
    <>
      <div className="about">
        <div className="container">
          <div className="about-image">
            <img src={card_1} alt="Biomedical Engineering" width="50%" />
          </div>
          <div className="about-text">
            <h2>Biomedical Engineering</h2>
            <p>
              Biomedical Engineering is an exciting and rapidly evolving field
              that combines the principles of engineering and medical sciences
              to innovate, design, and develop cutting-edge solutions that
              revolutionize healthcare and improve people's lives. At its core,
              biomedical engineering seeks to bridge the gap between medicine
              and technology, empowering healthcare professionals with advanced
              tools and techniques to diagnose, treat, and prevent diseases. It
              plays a pivotal role in developing new medical devices, diagnostic
              tools, therapies, and treatment methods, ultimately advancing
              healthcare and enhancing patient care.
            </p>
          </div>
        </div>
      </div>
      <div className="about">
        <div className="container">
        <div className="about-image">
            <img src={card_2} alt="Biomedical Engineering" />
          </div>
          <div className="about-text">
            <h2>Why Choose BME Bharat App?</h2>
            <p>
              BME Bharat app is your gateway to the fascinating realm of biomedical engineering. Whether you are an aspiring biomedical engineer, a medical healthcare professional, or just a curious individual intrigued by the intersection of technology and medicine, you've come to the right place. This app is tailored to meet your needs. At BME Bharat we have developed an easy-to-use mobile application where:
            </p>
            <ol className="custom-list">
              <li>A BME can find an easier way of connecting to people in the biomedical industry.</li>
              <li>Buyers can select from a range of vendors & suppliers.</li>
              <li>Manufacturers, Service providers, Dealers & Distributors can list their products to reach a huge audience.</li>
              <li>BMEs & Professionals will get regular newsletters/articles published by senior Bio-Medical professionals including Doctors, Professors, HODs of Biomedical institutes, Senior BioMed.</li>
              <li>You can share knowledge with a target audience.</li>
              <li>You can ask doubts/questions and get quick answers by experienced professionals.</li>
            </ol>
          </div>
          
        </div>
      </div>
      <div className="about">
        <div className="container">
          <div className="about-image">
            <img src={card_3} alt="Biomedical Engineering" />
          </div>
          <div className="about-text">
            <h2>Industry Insights</h2>
            <ol className="custom-list">
              <li><strong>Industry Insights:</strong> Stay updated with the conferences, workshops, industry events, latest trends, and advancements in biomedical engineering. Our app offers real-time news and expert insights from leading professionals, ensuring you never miss a crucial breakthrough or important opportunity to expand your knowledge and network.</li>
              <li><strong>Career Guidance:</strong> If you are considering a career in biomedical engineering, let us guide you through the various educational paths, job prospects, and skill requirements. Discover internship and job opportunities from top healthcare institutions and companies.</li>
              <li><strong>Innovation Showcase:</strong> Discover groundbreaking innovations and technological advancements in the healthcare industry. From prosthetics and wearable devices to state-of-the-art imaging technologies, explore the future of medicine at your fingertips.</li>
              <li><strong>Networking Opportunities:</strong> Connect with like-minded individuals, researchers, and professionals in the biomedical engineering community. Engage in discussions, share knowledge, exchange ideas, and collaborate on projects to contribute to the future of healthcare technology. We encourage discussions, forums, and networking opportunities, fostering a supportive environment for growth and learning.</li>
            </ol>
          </div>
        </div>
      </div>
      <hr style={{ width: "80%", margin: "auto" }} />
      <div className="about">
        <div className="container">
          <div className="about-text01">
            <h2>Vendor Directory</h2>
            <p>
              <strong>"Discover Leading Biomedical Engineering Companies and Suppliers"</strong>
              <br />
              Welcome to the Vendor Directory in our biomedical engineering app, where you can explore a curated list of top-notch companies and suppliers in the field. Whether you're looking for cutting-edge medical devices, quality lab equipment, or innovative biotechnology solutions, our directory is your one-stop resource for finding trusted partners.
              <br />
              <strong>In this section, you can:</strong>
            </p>
            <ol className="custom-list">
              <li><strong>Browse a Diverse Range of Vendors:</strong> We've carefully selected a diverse array of companies, from established industry leaders to innovative startups, ensuring you have access to a wide range of products and services.</li>
              <li><strong>Search and Filter:</strong> Our user-friendly interface allows you to search for specific vendors or products and apply filters to narrow down your options.</li>
              <li><strong>Detailed Profiles:</strong> Each vendor listing includes detailed information about the company, its product offerings, and contact details, making it easy to connect and inquire.</li>
              <li><strong>Direct Inquiries:</strong> Contact vendors directly through the app to request quotes, place orders, or seek further information.</li>
            </ol>
          </div>
        </div>
      </div>
      <div className="about">
        <div className="container">
         
          <div className="about-text01">
            <h2>Job Portal</h2>
            <p>
              <strong>"Your Gateway to Exciting Career Opportunities in Biomedical Engineering:"</strong>
              <br />
              Welcome to the Job Portal in our biomedical engineering app, where you can explore a vast database of job listings, ranging from research positions and project management roles to quality assurance and regulatory affairs in biomedical engineering.
              <br />
              <strong>In this section, you can:</strong>
            </p>
            <ol className="custom-list">
              <li><strong>Search and Discover:</strong> Explore a vast database of job listings, ranging from research positions and project management roles to quality assurance and regulatory affairs in biomedical engineering.</li>
              <li><strong>Filter and Refine:</strong> Utilize powerful filters to refine your search based on location, experience level, job type, and more, ensuring you find positions that align with your skills and preferences.</li>
              <li><strong>Detailed Listings:</strong> Each job listing provides comprehensive details about the position, including job responsibilities, qualifications required, and application deadlines.</li>
              <li><strong>One-Click Application:</strong> Apply to your desired positions with a single click, making it convenient and straightforward to kickstart your career or take the next step.</li>
              <li><strong>Wide Reach:</strong> When you post job updates on our platform, you gain access to a diverse and global community of biomedical engineers. Our users are not limited to a specific region or expertise, ensuring that your job listings reach a wide and qualified audience.</li>
              <li><strong>Specialized Audience:</strong> Your job updates are seen by individuals who are specifically interested in and qualified for roles within the biomedical engineering sector. This means you're more likely to connect with candidates who have the skills and passion you're seeking.</li>
              <li><strong>User-Friendly Interface:</strong> Our user-friendly interface makes it easy for companies to post job updates quickly and efficiently. You can create and manage listings, track applicant responses, and communicate with potential hires seamlessly.</li>
            </ol>
          </div>
        </div>
      </div>
      <div className="about">
        <div className="container">
          <div className="about-text01">
            <h2>Forum</h2>
            <p>
              Welcome to the Biomedical Engineering Forum, a dedicated space for professionals, students, and enthusiasts in the biomedical industry to connect, share knowledge, and support each other. Whether you're a seasoned expert or just starting your journey in biomedical engineering, this forum is the perfect place to ask questions, share insights, and collaborate on innovative ideas.
              <br />
              <strong>Why Join Our Forum?</strong>
            </p>
            <ol className="custom-list">
              <li><strong>Connect with a Community:</strong> Join a network of like-minded individuals passionate about biomedical engineering. Build professional relationships and collaborate on projects.</li>
              <li><strong>Learn and Grow:</strong> Access a wealth of knowledge from experts and peers. Stay ahead in your field with continuous learning and professional development.</li>
              <li><strong>Support and Be Supported:</strong> Whether you need help or can offer it, this forum thrives on mutual support. Engage in meaningful discussions and foster a culture of knowledge-sharing.</li>
              <li><strong>Ask Questions:</strong> Have a query about a complex biomedical concept, a technical challenge in your project, or career advice? Post your questions and get answers from experts and peers who are eager to help.</li>
              <li><strong>Share Knowledge:</strong> Contribute to the community by sharing your expertise. Answer questions, provide insights on recent advancements, or share useful resources and articles that could benefit others.</li>
              <li><strong>Get Project Support:</strong> Working on a project and hit a roadblock? Share the details and get feedback, suggestions, and solutions from fellow members who have faced similar challenges.</li>
              <li><strong>Discuss Latest Trends:</strong> Stay updated with the latest trends, research, and innovations in the biomedical industry. Join discussions on cutting-edge technologies, breakthrough research, and emerging trends.</li>
              <li><strong>Participate in Events:</strong> Discover and share information about upcoming events, conferences, webinars, and workshops. Network with other attendees and discuss key takeaways post-event.</li>
            </ol>
          </div>
         
        </div>
      </div>
      <div className="about">
        <div className="container">
          
          <div className="about-text01">
            <h2>Article Hub</h2>
            <p>
              Our Article Hub is your go-to source for insightful and up-to-date content on biomedical engineering. Here, you'll find a wide range of articles covering the latest advancements, research findings, industry trends, and practical applications in the field of biomedical engineering. Whether you're a student, researcher, professional, or enthusiast, our curated articles are designed to inform, inspire, and support your journey.
              <br />
              <strong>What You'll Discover:</strong>
            </p>
            <ol className="custom-list">
              <li><strong>Latest Research and Innovations:</strong> Stay updated with groundbreaking research and technological innovations that are shaping the future of biomedical engineering. Our articles highlight significant studies, new methodologies, and emerging technologies that are pushing the boundaries of what’s possible.</li>
              <li><strong>Industry Trends and Insights:</strong> Gain insights into current and future trends in the biomedical industry. Our expert contributors analyze market dynamics, regulatory changes, and technological advancements to provide you with a comprehensive understanding of the industry landscape.</li>
              <li><strong>Practical Applications:</strong> Discover practical applications of biomedical engineering in healthcare, diagnostics, therapeutics, and more. Learn how innovative solutions are being implemented in real-world scenarios to improve patient outcomes and advance medical practices.</li>
              <li><strong>Expert Opinions and Interviews:</strong> Read thought-provoking articles and interviews with leading experts in the field. Gain perspectives from renowned researchers, industry leaders, and innovators who are making significant contributions to biomedical engineering.</li>
              <li><strong>Educational Resources:</strong> Access a wealth of educational articles that explain complex concepts in a clear and understandable manner. These resources are perfect for students and professionals looking to deepen their knowledge and stay current with industry developments.</li>
              <li><strong>Case Studies and Success Stories:</strong> Explore detailed case studies and success stories that showcase how biomedical engineering is solving real-world problems. Learn from the experiences of others and get inspired by their achievements and breakthroughs.</li>
              <li><strong>How to Contribute:</strong> We welcome contributions from our community. If you have an article, research paper, case study, or opinion piece you'd like to share, please submit your content through our submission portal. Our editorial team will review your submission and publish it if it meets our quality standards.</li>
            </ol>
          </div>
        </div>
      </div>

    
    </>
  );
};

export default About;