import React from 'react'
import "./ComingSoon.css";

function ComingSoon() {
  return (
    
      <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h3>Coming Soon...</h3>
        </div>
      </div>
    </div>
 
  )
}

export default ComingSoon
