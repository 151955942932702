import React from "react";

import "./Refunds.css";
const Refunds = () => {
  return (
    <>
      <div className="terms">
        <h1>Terms and conditions</h1>
        <hr />

        <p>
          Welcome to BME Bharat App, by accessing or using the App, you agree to
          be bound by these Terms and Conditions. If you do not agree to these
          Terms, please do not use the App.
          <br />
          <strong>Use of the App</strong>
          <br />
          <strong>Eligibility: </strong>
          You must be at least 18 years old to use the App. By using the App,
          you represent and warrant that you meet this age requirement.
          <br />
          <strong>License: </strong>
          We grant you a limited, non-exclusive, non-transferable, revocable
          license to use the App for your personal, non-commercial use, subject
          to these Terms.
          <br />
          <strong>Prohibited Conduct</strong>
          <br />
          <strong>You agree not to:</strong>
          Use the App for any illegal purpose or in violation of any local,
          state, national, or international law.
          <br />
          Attempt to gain unauthorized access to the App, other user accounts,
          or any computer systems or networks connected to the App.
          <br />
          Transmit any viruses, worms, defects, Trojan horses, or any items of a
          destructive nature.
          <br />
          Use the App to transmit unsolicited commercial emails ("spam").
          <br />
          <strong>User Accounts</strong>
          <br />
          <strong>Account Creation: </strong>To use certain features of the App,
          you may need to create an account. You agree to provide accurate and
          complete information when creating your account.
          <br />
          <strong>Account Security:</strong>You are responsible for maintaining
          the confidentiality of your account password and for all activities
          that occur under your account. You agree to notify us immediately of
          any unauthorized use of your account.
          <br />
          <strong>Privacy: </strong>Our Privacy Policy describes how we collect,
          use, and disclose information about you. By using the App, you agree
          to our Privacy Policy.
          <br />
          <strong>Intellectual Property</strong>
          <br />
          <strong>Ownership:</strong> All content and materials available on the
          App, including but not limited to text, graphics, logos, and software,
          are the property of [Your Company Name] or its licensors and are
          protected by intellectual property laws.
          <br />
          <strong>Trademarks:</strong> BME Bharat and all related names, logos,
          product and service names, designs, and slogans are trademarks of BME
          Bharat or its affiliates or licensors. You may not use such marks
          without the prior written permission of BME Bharat.
          <br />
          <strong>Disclaimers and Limitation of Liability</strong>
          <br />
          <strong>No Medical Advice:</strong> The App is provided for
          informational purposes only and is not intended as a substitute for
          professional medical advice, diagnosis, or treatment. Always seek the
          advice of your physician or other qualified health provider with any
          questions you may have regarding a medical condition.
          <br />
          <strong>Disclaimer of Warranties:</strong> The App is provided on an
          "as is" and "as available" basis, without any warranties of any kind,
          either express or implied. We do not warrant that the App will be
          uninterrupted or error-free, that defects will be corrected, or that
          the App is free of viruses or other harmful components.
          <br />
          <strong>Limitation of Liability: </strong>To the fullest extent
          permitted by law, we disclaim all liability, whether based in
          contract, tort (including negligence), strict liability, or otherwise,
          and will not be liable for any indirect, incidental, consequential, or
          punitive damages arising out of or related to your use of the App.
          <br />
          <strong>Changes to the Terms</strong>
          <br />
          We reserve the right to modify these Terms at any time. Any changes
          will be effective immediately upon posting the updated Terms on the
          App. Your continued use of the App after the posting of the changes
          constitutes your acceptance of the changes.
          <br />
          <strong>Contact Us</strong>
          <br />
          If you have questions or concerns about our legal compliance specific
          to Bharat or require assistance related to legal matters
          <br /> please contact us at Email{" "}
          <span style={{ color: "blue" }}>admin@bmebharat.com</span> or{" "}
          <span style={{ color: "blue" }}>bmebharat@gmail.com</span> or Phone
          Number <span style={{ color: "blue" }}>+91 8310491223.</span>
        </p>
      </div>
    </>
  );
};

export default Refunds;
