import React from "react";
import "./PrivacyPolicy.css";
import {
  FaHandsHelping,
  FaLock,
  FaComments,
  FaFingerprint,
} from "react-icons/fa";
const PrivacyPolicy = () => {
  return (
    <>
      <div className="privacy">
        <h1>Privacy Policy</h1>
        <hr></hr>

        <p>
          Effective Date: 01/July/2024
          <br />
          This Privacy Policy outlines how B M E Bharat ("we," "us," or "our")
          collects, uses, discloses, and protects the personal information of
          users of our biomedical engineering app B M E Bharat. We are committed
          to safeguarding your privacy and ensuring the security of your
          personal data. By using the App, you agree to the terms and practices
          described in this Privacy Policy.
          <br />
          <strong>Information We Collect:</strong>
          User-Provided Information: We may collect information that you
          voluntarily provide when using our App, including but not limited to:
          <br />
          Your name
          <br />
          Email address
          <br />
          Contact information
          <br />
          Profile information
          <br />
          Content you submit, post, or share on the App <br />
          Automatically Collected Information: We may automatically collect
          information about your usage of the App, such as: Device information
          (e.g., device type, operating system) Log data (e.g., IP address,
          browser type, date and time of access) Location data (if you enable
          location services)
          <br />
          <strong>How We Use Your Information:</strong>
          We use the collected information for the following purposes: To
          provide and maintain the App. To personalize and improve your
          experience with the App. To communicate with you, including sending
          notifications, updates, and information about our services. To respond
          to your requests, comments, or questions. To analyze user trends and
          preferences to enhance the App's features and content. To fulfil legal
          and regulatory obligations.
          <br />
          <strong>Sharing of Your Information:</strong>
          We do not sell, trade, or rent your personal information to third
          parties. However, we may share your information with: Service
          providers and third-party vendors who assist us in operating and
          maintaining the App. Legal authorities or other entities when required
          to comply with the law or protect our rights and interests.
          <br />
          <strong>Your Choices and Controls:</strong>
          You have certain rights and choices regarding your personal
          information: You can review and update your account information at any
          time. You may opt out of receiving marketing communications from us.
          You can disable location services through your device settings. You
          can request the deletion of your account and associated data.
          <br />
          <strong>Security:</strong>
          We take reasonable measures to protect your information from
          unauthorized access, disclosure, alteration, or destruction. However,
          no method of transmission over the internet or electronic storage is
          entirely secure. We cannot guarantee the absolute security of your
          data.
          <br />
          <strong>Children's Privacy:</strong>
          Our App is not intended for children under the age of 13. We do not
          knowingly collect personal information from individuals under the age
          of 13. If you believe we have inadvertently collected such
          information, please contact us to have it removed.
          <br />
          <strong>Changes to this Privacy Policy:</strong>
          We may update this Privacy Policy to reflect changes in our practices
          or for other operational, legal, or regulatory reasons. We will
          provide notice of any material changes and obtain your consent if
          required by applicable laws.
          <br />
          <strong>Contact Us:</strong>
          If you have any questions, concerns, or requests related to this
          Privacy Policy If you have questions or concerns about our legal
          compliance specific to Bharat or require assistance related to legal
          matters
          <br /> please contact us at Email{" "}
          <span style={{ color: "blue" }}>admin@bmebharat.com</span> or{" "}
          <span style={{ color: "blue" }}>bmebharat@gmail.com</span> or Phone
          Number <span style={{ color: "blue" }}>+91 8310491223.</span>
          <br />
          By using our App, you consent to the practices described in this
          Privacy Policy. Please review this policy regularly to stay informed
          about how we handle your personal information.
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
